.fixed-navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: white;
  color: black;
  padding: 10px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



@media (min-width: 300px) {
  .fixed-navbar-wrapper {
    padding-left: 10px;
  }
}
